<script>
	import Base from '@backend/Base.vue';
  	import BOGen from '@helper/BOGen';
  	import Gen from '@helper/Gen';

	export default {
		name: "WebSettings",
		extends: Base,
		data() {
			return {
				Name: "WebSettings",
				sc1: {},
				sc2: {},
				sc3: {},
				contact: {},
			}
		},
		mounted() {
			this.$set(this.$root, 'page', this)
			this.refreshData()
		},
		methods: {
		    submitHilo() {
		    	this.sc1.type = 'updateHilo'
		      BOGen.apirest('/' + this.Name, this.sc1, (err, resp) => {
		        if (resp.error) return Gen.info(resp.message, resp.style, 3000, '.hilo-info')
		        if (resp.success) {
		          Gen.info(resp.message, resp.style, 3000, '.hilo-info')
		          this.refreshData()
		        }
		      }, "POST");
		    },
		    submitTeen() {
		    	this.sc2.type = 'updateTeen'
		      BOGen.apirest('/' + this.Name, this.sc2, (err, resp) => {
		        if (resp.error) return Gen.info(resp.message, resp.style, 3000, '.teen-info')
		        if (resp.success) {
		          Gen.info(resp.message, resp.style, 3000, '.teen-info')
		          this.refreshData()
		        }
		      }, "POST");
		    },
		    submitSchool() {
		    	this.sc3.type = 'updateSchool'
		      BOGen.apirest('/' + this.Name, this.sc3, (err, resp) => {
		        if (resp.error) return Gen.info(resp.message, resp.style, 3000, '.school-info')
		        if (resp.success) {
		          Gen.info(resp.message, resp.style, 3000, '.school-info')
		          this.refreshData()
		        }
		      }, "POST");
		    },
		    submitContact() {
		    	this.contact.type = 'updateContact'
		      BOGen.apirest('/' + this.Name, this.contact, (err, resp) => {
		        if (resp.error) return Gen.info(resp.message, resp.style, 3000, '.contact-info')
		        if (resp.success) {
		          Gen.info(resp.message, resp.style, 3000, '.contact-info')
		          this.refreshData()
		        }
		      }, "POST");
		    },
		},
		watch: {
			'$route.query'() {
				this.refreshData()
			},
			'filter.level'() {
				this.search()
			},
			'filter.search'(v){
		        this.search()
		    }
		}
	}
</script>
<style type="text/css">
	.image-preview{
		background-color: #08affb12;
		padding: 3px;
	}
	
</style>
<template>
	<div class="container-fluid">
		<PageTitle></PageTitle>

		<!-- ======================================================================== 
			LIST DATA
		============================================================================= -->
		<div class="row" v-if="!$route.params.id">
			<div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
				<div class="card">
					<div class="card-body">
						<VForm @resp="search">
							<div class="row gutter-10">
								<div class="col-sm-5">
									<h5 class="card-title">{{ObjectName}} List</h5>
								</div>
								<div class="col-9 col-lg-3 col-sm-4 ml-auto">
									<div class="form-group mb-0">
										<div class="input-group">
											<input type="text" v-model="filter.search" class="form-control" placeholder="Search...">
											<div class="input-group-append">
												<button class="btn btn-info" type="button" @click="search()"><i
														class="fas fa-search"></i></button>
											</div>
										</div>
									</div>
								</div>
								<div class="col-3 col-sm-auto">
									<router-link :to="{name:Name}" class="btn btn-warning">Reset</router-link>
								</div>
							</div>
						</VForm>
					</div>
					<div class="table-responsive ajax-table">
						<table class="table table-striped table-bordered">
							<thead>
								<tr>
									<th>#</th>
									<th>
										<SortField name="as_name"></SortField>
									</th>
									<th>Content</th>
									<th class="text-center" width="120px">Action</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(v,k) in data.data" :key="k">
									<td class="number">{{(data.per_page*(data.current_page-1))+k+1}}</td>
									<td>{{(v.as_name||'-')}}</td>
									<td>{{(v.as_val||'-')}}</td>
									<td class="btn-action text-center">
										<router-link v-if="page.moduleRole('Edit')" class="icon_action" :to="{name:Name,params:{id:v.id}}" v-tooltip="'Edit'"><i
												class="ti-marker-alt"></i></router-link>
									</td>
								</tr>
								<tr v-if="NotFound">
									<td colspan="99">
										<h3 class="tc">{{NotFound}}</h3>
									</td>
								</tr>
								<tr v-if="data.data===false">
									<td colspan="99">
										<LoadingSpinner light></LoadingSpinner>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<Pagination class="justify-content-end mt-3 mb-0" :data="data" :limit="3" @pagination-change-page="onPaging"></Pagination>
				</div>
			</div>

			<div class="col-md-4 col-lg-4 col-sm-12 col-xs-12">
				<div class="card">
					<div class="card-header">
						<h5 class="card-title">HiLo</h5>
					</div>
					<div class="card-body">
						<div class="hilo-info"></div>
						<VForm @resp="submitHilo" method="post">
							<BoField name="am_logo" :label="'Logo'">
			                  <Uploader name="am_logo" type="logo" uploadType="cropping" :param="{thumbnail:true}" v-model="sc1.am_logo"></Uploader>
			                </BoField>
							<boField name="am_link_fb" :attr="{type:'url'}" v-model="sc1.am_link_fb"></boField>
							<boField name="am_link_ig" :attr="{type:'url'}" v-model="sc1.am_link_ig"></boField>
							<boField name="am_link_tw" :attr="{type:'url'}" v-model="sc1.am_link_tw"></boField>
							<boField name="am_link_yt" :attr="{type:'url'}" v-model="sc1.am_link_yt"></boField>
							<div class="text-right"><button type="submit" class="fcbtn btn btn-info btn-rounded btn-outline btn-1e btn-loading">Update<i class="icon-arrow-right14 position-right"></i></button></div>
						</VForm>
					</div>
				</div>
			</div>

			<div class="col-md-4 col-lg-4 col-sm-12 col-xs-12">
				<div class="card">
					<div class="card-header">
						<h5 class="card-title">HiLo Teen</h5>
					</div>
					<div class="card-body">
						<div class="teen-info"></div>
						<VForm @resp="submitTeen" method="post">
							<BoField name="am_logo" :label="'Logo'">
			                  <Uploader name="am_logo" type="logo" uploadType="cropping" :param="{thumbnail:true}" v-model="sc2.am_logo"></Uploader>
			                </BoField>
							<boField name="am_link_fb" :attr="{type:'url'}" v-model="sc2.am_link_fb"></boField>
							<boField name="am_link_ig" :attr="{type:'url'}" v-model="sc2.am_link_ig"></boField>
							<boField name="am_link_tw" :attr="{type:'url'}" v-model="sc2.am_link_tw"></boField>
							<boField name="am_link_yt" :attr="{type:'url'}" v-model="sc2.am_link_yt"></boField>
							<div class="text-right"><button type="submit" class="fcbtn btn btn-info btn-rounded btn-outline btn-1e btn-loading">Update<i class="icon-arrow-right14 position-right"></i></button></div>
						</VForm>
					</div>
				</div>
			</div>

			<div class="col-md-4 col-lg-4 col-sm-12 col-xs-12">
				<div class="card">
					<div class="card-header">
						<h5 class="card-title">HiLo School</h5>
					</div>
					<div class="card-body">
						<div class="school-info"></div>
						<VForm @resp="submitSchool" method="post">
							<BoField name="am_logo" :label="'Logo'">
			                  <Uploader name="am_logo" type="logo" uploadType="cropping" :param="{thumbnail:true}" v-model="sc3.am_logo"></Uploader>
			                </BoField>
							<boField name="am_link_fb" :attr="{type:'url'}" v-model="sc3.am_link_fb"></boField>
							<boField name="am_link_ig" :attr="{type:'url'}" v-model="sc3.am_link_ig"></boField>
							<boField name="am_link_tw" :attr="{type:'url'}" v-model="sc3.am_link_tw"></boField>
							<boField name="am_link_yt" :attr="{type:'url'}" v-model="sc3.am_link_yt"></boField>
							<div class="text-right"><button type="submit" class="fcbtn btn btn-info btn-rounded btn-outline btn-1e btn-loading">Update<i class="icon-arrow-right14 position-right"></i></button></div>
						</VForm>
					</div>
				</div>
			</div>

			<div class="col-md-12">
				<div class="card">
					<div class="card-header">
						<h5 class="card-title">Contact</h5>
					</div>
					<div class="card-body">
						<div class="contact-info"></div>
						<VForm @resp="submitContact" method="post" class="row">
							<div class="col-md-2">
								<BoField name="logo" :label="'Logo'">
				                  <Uploader name="logo" type="logocontact" uploadType="cropping" :param="{thumbnail:true}" v-model="contact.logo"></Uploader>
				                </BoField>
							</div>
							<div class="col-md-5">
								<BoField name="contact" mandatory :label="'Contact'">
				                  <CKEditor name="contact" v-model="contact.contact" v-bind="validation('contact')"></CKEditor>
				                </BoField>  
							</div>
							<div class="col-md-5">
								<BoField name="disclaimer" mandatory :label="'Disclaimer'">
				                  <CKEditor name="disclaimer" v-model="contact.disclaimer" v-bind="validation('disclaimer')"></CKEditor>
				                </BoField>  
							</div>
							<div class=" col-md-12 text-right"><button type="submit" class="fcbtn btn btn-info btn-rounded btn-outline btn-1e btn-loading">Update<i class="icon-arrow-right14 position-right"></i></button></div>
						</VForm>
					</div>
				</div>
			</div>
		</div>

		<!-- ======================================================================== 
			FORM CRUD	
		============================================================================= -->
		<div class="card" v-if="$route.params.id">
			<VForm @resp="submitForm" method="post">
				<div class="card-body">
					<div class="row mb-3">
						<div class="col-md-8">
							<h5 class="card-title">{{(row.id?"Edit":"Add")+" "+ObjectName}}</h5>
						</div>
					</div>
					<div class="info"></div>
					<div class="row">
						<div class="col-sm-6">
							<BoField name="as_name" v-model="row.as_name"></BoField>
						</div>
						<div class="col-sm-6">
							<BoField name="as_val" v-if="$route.params.id == 21" :attr="{type:'email'}" v-model="row.as_val"></BoField>
							<BoField name="as_val" v-else v-model="row.as_val" :attr="{type:'number'}"></BoField>							
						</div>
						<div class="col-12">
							<div class="text-right">
								<button type="submit" class="fcbtn btn btn-info btn-outline btn-rounded btn-loading">Update Setting</button>
							</div>
						</div>
					</div>
				</div>
			</VForm>
		</div>
	</div>
</template>